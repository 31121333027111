.passwordResetCard {
  width: 50vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* mobile */
@media only screen and (max-width: 600px) {
  .passwordResetCard {
    width: 100vw;
  }
}
