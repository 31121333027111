.loginCard {
  width: 50vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.input {
  text-align: start;
  outline: 1px solid #000;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* mobile */
@media only screen and (max-width: 600px) {
  .loginCard {
    width: 100vw;
  }
}
