.slide {
  min-height: 70vh;
  max-height: 150vh;
}

/* mobile */
@media screen and (max-width: 768px) {
  .slide {
    min-height: 40vh;
    max-height: 150vh;
  }
  .postCard {
    margin: 0rem;
  }
}
