.empty {
  height: 100vh;
}

.checkmark {
  font-size: 5em;
  color: green;
}

@media only screen and (min-width: 768px) {
  .bigpostwall {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: "100vh";
  }
  ion-list {
    width: 50vw;
  }
}
