.widthResize {
  width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: "center";
  align-items: "center";
  text-align: center;
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: "center";
  align-items: "center";
}
@media only screen and (max-width: 600px) {
  .widthResize {
    width: 70vw;
  }
  .section {
    display: flex;
    flex-direction: column;
  }
}
