.form {
  width: 70%;
  align-items: center;
  justify-content: center;
  padding: 3em;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.centercolumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.fullwidthFlexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: 1em;
}

.maxSizePadded {
  padding: "5rem";
}
.mobileResize {
  width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: "center";
  align-items: "center";
  text-align: center;
  padding: 1em;
}

.mobileResizeBigger {
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: "center";
  align-items: "center";
  text-align: center;
  padding: 1em;
}
/* mobile */
@media (max-width: 768px) {
  .form {
    width: 100%;
  }
  .mobileResize {
    width: 95vw;
  }
  .mobileResizeBigger {
    width: 95vw;
  }
  .maxSizePadded {
    padding: "1rem";
  }
}
